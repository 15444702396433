import React, { RefObject } from "react";
import clsx from "clsx";
import { withRouter } from "next/router";
import NextLink from "next/link";
// import { Link as NextLink } from "../../i18n";
import MuiLink from "@material-ui/core/Link";
import theme from "../theme";

type Props = {
  as: string;
  href: string;
  prefetch: boolean;
  children?: React.ReactNode;
};
type Ref =
  | string
  | ((instance: HTMLAnchorElement | null) => void)
  | RefObject<HTMLAnchorElement>
  | null
  | undefined;

const NextComposed = React.forwardRef((props: Props, ref: Ref) => {
  const { as, href, ...other } = props;

  return (
    <NextLink href={href} as={as}>
      <a
        ref={ref}
        {...other}
        style={{ textDecoration: "none" }}
        onClick={() => {
          // FIXME: why is news not working?
          if (as && as !== "/performances") {
            window.location.pathname = as;
          }
        }}
      />
    </NextLink>
  );
});

// A styled version of the Next.js Link component:
// https://nextjs.org/docs/#with-link

// TODO: make this work
// type LinkProps = {
//   activeClassName: string;
//   as: string;
//   className: string;
//   href: string;
//   innerRef:
//     | ((instance: HTMLAnchorElement | null) => void)
//     | RefObject<HTMLAnchorElement>
//     | null
//     | undefined;
//   naked: boolean;
//   onClick: () => void;
//   prefetch: boolean;
//   router: {
//     pathname: string;
//   };
// };

const Link = (props: any) => {
  const {
    activeClassName,
    router,
    className: classNameProps,
    innerRef,
    naked,
    ...other
  } = props;

  const className = clsx(classNameProps, {
    [activeClassName]: router.pathname === props.href && activeClassName,
  });

  if (naked) {
    return <NextComposed className={className} ref={innerRef} {...other} />;
  }

  return (
    <MuiLink
      component={NextComposed}
      className={className}
      ref={innerRef}
      {...other}
    />
  );
};

Link.defaultProps = {
  activeClassName: "active",
};

const RouterLink = withRouter(Link);

// TODO: remove another any
export default React.forwardRef((props: Props, ref) => (
  <RouterLink
    {...props}
    innerRef={ref}
    style={{
      // fontSize: `${ptToVwConverter(14)}vw`,
      fontSize: "14px",
      color: theme.palette.primary,
    }}
  />
)) as any;
